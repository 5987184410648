<template>
  <validation-observer ref="observer">
    <v-form ref="form" @submit.prevent="changeUserPassword">
      <h2 class="settings__title">
        Change Password
      </h2>
      <validation-provider
        v-slot="{ errors }"
        name="current_password"
        rules="required|max:255|min:6"
      >
        <text-field
          v-model="formData.current_password"
          class="mb-2"
          label="Current Password"
          :type="!password.current_password ? 'password' : 'text'"
          :append-icon="password.current_password ? 'visibility' : 'visibility_off'"
          :class="{'error-status': currentStatus}"
          large
          outlined
          required
          :error-messages="errors"
          @click:append="password.current_password = !password.current_password"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="password"
        rules="required|max:255|min:6"
      >
        <text-field
          v-model="formData.password"
          class="mb-2"
          label="New Password"
          :type="!password.password ? 'password' : 'text'"
          :error-messages="confirm ? confirmText : errors"
          :append-icon="password.password ? 'visibility' : 'visibility_off'"
          large
          outlined
          required
          @click:append="password.password = !password.password"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="confirm_password"
        rules="required|max:255|min:6"
      >
        <text-field
          v-model="formData.password_confirmation"
          label="Repeat New Password"
          :type="!password.password_confirmation ? 'password' : 'text'"
          :append-icon="password.password_confirmation ? 'visibility' : 'visibility_off'"
          large
          outlined
          required
          :error-messages="confirm ? confirmText : errors"
          @click:append="password.password_confirmation = !password.password_confirmation"
        />
      </validation-provider>
      <primary-button
        class="mt-5"
        :loading="loading"
        type="submit"
      >
        Change Password
      </primary-button>
    </v-form>
  </validation-observer>
</template>

<script>
import TextField from '@/components/ui/TextField/TextField';
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';

export default {
  name: 'Password',
  components: { PrimaryButton, TextField },
  data() {
    return {
      formData: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      confirm: false,
      confirmText: 'Password must match',
      loading: false,
      password: {
        current_password: false,
        password: false,
        password_confirmation: false,
      },
    };
  },
  computed: {
    currentStatus() {
      return this.$store.getters.getCurrentPasswordStatus;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        this.confirm = !!(val.password !== val.password_confirmation
          && val.password_confirmation?.length);
      },
    },
  },
  methods: {
    changeUserPassword() {
      const observerRef = this.$refs.observer;
      this.$store.commit('updateCurrentPasswordStatus', false);

      observerRef.validate().then((isValid) => {
        console.log(isValid, 'isValid');

        if (isValid) {
          this.loading = true;
          this.$store.dispatch('UPDATE_USER_PASSWORD', this.formData)
            .then((response) => {
              this.loading = false;
              if (response.status === 422) return;
              this.$refs.form.reset();
              observerRef.reset();
            }).catch((err) => {
              console.log(err, 'error');
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .error-status {
    ::v-deep .v-input__slot {
      fieldset {
        border: 2px solid $error;
      }
    }
  }
</style>
